@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,600;1,700;1,800&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Plus Jakarta Sans",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #144632;
  color: #4e5554;
  font-size: 12px;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 14px;
}

p {
  font-size: 12px;
}

.section {
  background-color: #eeeeee;
  border-radius: 16px;
}

.section-grey {
  background-color: #f5f5f5;
  border-radius: 16px;
}

.section-white {
  background-color: #fff;
  border-radius: 16px;
}

.arrow {
  border: solid;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #5d7d731a;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5d7d73;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.time-picker-fix div input {
  width: 40px !important;
}

.uploadimage {
  position: relative;
  cursor: pointer;
  border-radius: 0.75rem /* 12px */;
  width: 100%;
  height: 100%;
}

.uploadimage .overlay {
  position: absolute;
  right: 0;
  padding: 10px;
  z-index: 5;
}

.uploadimage .overlay:hover {
  transform: scale(1.2);
}

.overlay-img:hover {
  transform: scale(1.02);
}
